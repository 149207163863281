import { 
  StyledHelpUsGrow, 
  StyledHelpUsGrowHeader, 
  StyledHelpUsGrowHeaderBold, 
  StyledHelpUsGrowHeaderDesc, 
  StyledHorizontalLine 
} from './HelpUsGrow.styles';
import HelpUsGrowHeader from '@/shared/HelpUsGrowHeader/HelpUsGrowHeader';
import { useTranslation } from 'react-i18next';
import RefLinkForm from './RefLinkForm/RefLinkForm';
import DownloadLogoForm from './DownloadLogoForm/DownloadLogoForm';

const HelpUsGrow = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StyledHelpUsGrow>
      <HelpUsGrowHeader />
      <StyledHorizontalLine />
      <StyledHelpUsGrowHeader>
        {t('help_us_grow_form_header')}
        <br />
        <StyledHelpUsGrowHeaderBold>
          {t('help_us_grow_form_header_bold')}
        </StyledHelpUsGrowHeaderBold>
      </StyledHelpUsGrowHeader>
      <StyledHelpUsGrowHeaderDesc>{t('help_us_grow_form_header_desc')}</StyledHelpUsGrowHeaderDesc>
      <RefLinkForm />
      <StyledHelpUsGrowHeaderDesc>{t('help_us_grow_form_desc')}</StyledHelpUsGrowHeaderDesc>
      <DownloadLogoForm />
      <StyledHelpUsGrowHeaderDesc>{t('download_logo_desc')}</StyledHelpUsGrowHeaderDesc>
    </StyledHelpUsGrow>
  );
};

export default HelpUsGrow;
