import styled, { css } from 'styled-components';

export interface StyledAppProps {
  blockHeight?: boolean;
}

export const StyledApp = styled.div<StyledAppProps>`
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  scroll-behavior: smooth;
  overflow: hidden;

  ${({ blockHeight }) => blockHeight && css`
    height: 100vh;
  `}
`;
