import Button from '@/shared/Button/Button';
import Card from '@/shared/Card/Card';
import styled from 'styled-components';
import { ReactComponent as SmileSvg } from '@img/smile.svg';
import { FontNames } from '@/common/styles/fontNames';

export const StyledDownloadLogoForm = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
`;

export const StyledSmile = styled(SmileSvg)`
  margin: 15px 0 20px 0;
`;

export const StyledDescription = styled.p`
  font-family: ${FontNames.graphikMedium};
  text-align: center;
  margin: 15px 0;
  max-width: 400px;
`;

export const StyledDownloadButton = styled(Button)`
  margin-top: 15px;
`;
