import { all, race } from 'redux-saga/effects';

import TokenSaga from './tokenSaga';
import WalletSaga from './walletSaga';
import VestingSaga from './vestingSaga';
import PresaleSaga from './presaleSaga';
import ReferralSaga from './referralSaga';

export default function* rootSaga(): Generator {
  yield race([
    all([
      TokenSaga(),
      WalletSaga(),
      VestingSaga(),
      PresaleSaga(),
      ReferralSaga(),
    ])
  ]);
}
