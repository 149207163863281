import LoadingIcon from '../LoadingIcon/LoadingIcon';
import { StyledCard, StyledCardContent, StyledCardLoading } from './Card.styles';

export interface CardProps {
  className?: string
  loading?: boolean;
}

const Card = ({ className, children, loading }: React.PropsWithChildren<CardProps>): JSX.Element => {
  return (
    <StyledCard>
      <StyledCardContent loading={loading} className={className}>
        {children}
      </StyledCardContent>
      {loading && 
        <StyledCardLoading>
          <LoadingIcon />
        </StyledCardLoading>
      }
    </StyledCard>
  );
};

export default Card;
