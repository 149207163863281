import {
  StyledHeader,
  StyledHeaderContent
} from './Header.styles';
import DesktopNav from './DesktopNav/DesktopNav';
import MobileNav from './MobileNav/MobileNav';
import { memo, useMemo } from 'react';
import { RouteNames } from '@/common/constants';
import { homePageSelectors } from '@/common/store/homePageSlice';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const FIXED_NAV_BAR_BREAK_POINT = 80;

const Header = () => {
  const location = useLocation();
  const scrollY = useSelector(homePageSelectors.scrollY);

  const isNavBarFixed = useMemo(
    () => scrollY > FIXED_NAV_BAR_BREAK_POINT || RouteNames.home !== location.pathname,
    [scrollY, location]
  );

  return (
    <StyledHeader isNavBarFixed={isNavBarFixed}>
      <StyledHeaderContent>
        <DesktopNav />
        <MobileNav />
      </StyledHeaderContent>
    </StyledHeader>
  );
};

export default memo(Header);
