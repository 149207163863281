import { FontNames } from '@/common/styles/fontNames';
import Button from '@/shared/Button/Button';
import Card from '@/shared/Card/Card';
import styled from 'styled-components';

export const StyledRefLinkForm = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledRefLinkFormHeader = styled.h3`
  font-family: ${FontNames.graphikMedium};
  margin-bottom: 10px;
  text-align: center;
`;

export const StyledRefLink = styled.p`
  font-family: ${FontNames.graphikLight};
  margin-bottom: 10px;
  text-align: center;
`;

export const StyledSubmitButton = styled(Button)`
  margin-top: 15px;
`;
