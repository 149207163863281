import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useWindowScroll, useWindowSize } from 'react-use';
import { StyledAppearOnScrollContainer, StyledAppearOnScrollTarget } from './AppearOnScroll.styles';
import gsap from 'gsap';
import { MOBILE_MAX_WIDTH } from '@common/styles/media';
import { uniqueId } from 'lodash';
import { MAX_APP_WIDTH } from '@common/constants';

export enum AppearAnimation {
  fadeIn = 'appear-on-scroll--fadeIn',
  fadeInBottom = 'appear-on-scroll--fadeInBottom',
  slideIn = 'appear-on-scroll--slideIn',
  countIn = 'appear-on-scroll--countIn',
}

export enum AnimationDir {
  in,
  out
}

export enum AnimationType {
  mobile,
  desktop
}

export interface AppearOnScrollContextProps {
  animationDir: AnimationDir;
  animationType: AnimationType;
}

const AppearOnScrollContext = createContext<AppearOnScrollContextProps>({
  animationDir: AnimationDir.out,
  animationType: AnimationType.desktop
});

export interface AppearOnScrollContainerProps {
  desktopThreshold?: number;
  mobileTreshold?: number;
  className?: string;
}

export const AppearOnScrollContainer = ({
  desktopThreshold = 0,
  mobileTreshold = 0,
  children,
  className
}: PropsWithChildren<AppearOnScrollContainerProps>): JSX.Element => {
  const [animationDir, setAnimationDir] = useState<AnimationDir>(AnimationDir.out);
  const [animationType, setAnimationType] = useState<AnimationType>(
    window.innerWidth < MAX_APP_WIDTH ? AnimationType.mobile : AnimationType.desktop
  );
  const { width } = useWindowSize();
  const { y } = useWindowScroll();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const threshold = useMemo(() => {
    return animationType === AnimationType.mobile ? mobileTreshold : desktopThreshold;
  }, [animationType, mobileTreshold, desktopThreshold]);

  useEffect(() => {
    setAnimationType(width < MOBILE_MAX_WIDTH ? AnimationType.mobile : AnimationType.desktop);
  }, [width]);

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }

    const isPassed = y > wrapperRef.current.offsetTop;

    if (y + window.innerHeight / 2 - threshold * window.innerHeight + 60 < wrapperRef.current.offsetTop && !isPassed) {
      setAnimationDir(AnimationDir.out);
    } else {
      setAnimationDir(AnimationDir.in);
    }

  }, [y, threshold]);

  return (
    <StyledAppearOnScrollContainer className={className} ref={wrapperRef}>
      <AppearOnScrollContext.Provider value={{
        animationDir,
        animationType
      }}>
        {children}
      </AppearOnScrollContext.Provider>
    </StyledAppearOnScrollContainer>
  );
};

export interface AppearOnScrollTargetProps {
  desktopAnimation?: AppearAnimation;
  mobileAnimation?: AppearAnimation;
  numericAnimationContent?: string;
  delay?: number;
}

export const AppearOnScrollTarget = ({
  children,
  desktopAnimation = AppearAnimation.fadeInBottom,
  mobileAnimation = desktopAnimation,
  delay = 0.1,
  numericAnimationContent
}: PropsWithChildren<AppearOnScrollTargetProps>): JSX.Element => {
  const targetRef = useRef<HTMLSpanElement>(null);
  const [componentUid] = useState(uniqueId('AppearOnScrollTarget-'));
  const { animationDir, animationType } = useContext(AppearOnScrollContext);

  const animation = useMemo(() => (
    animationType === AnimationType.mobile ? mobileAnimation : desktopAnimation
  ), [animationType]);

  const stagger = 0.2;

  const countIn = useCallback((element: gsap.TweenTarget) => {
    gsap.from(element, {
      delay: 0,
      textContent: 0,
      duration: 3,
      ease: 'power2.out',
      snap: { textContent: 0.1 },
      onComplete: () => {
        if (targetRef.current && numericAnimationContent) {
          targetRef.current.innerText = numericAnimationContent;
        }
      }
    });
  }, [delay]);

  const fadeInBottom = useCallback((element: gsap.TweenTarget) => {
    gsap.fromTo(element, {}, {
      delay,
      opacity: 1,
      transform: 'scale(1)',
      y: 0,
      ease: 'power2.out',
      stagger: {
        amount: stagger
      }
    });
  }, [delay]);

  const fadeOutBottom = useCallback((element: gsap.TweenTarget) => {
    gsap.fromTo(element, {}, {
      opacity: 0,
      y: 30,
      transform: 'scale(0.9)',
      ease: 'power2.out'
    });
  }, []);

  const fadeIn = useCallback((element: gsap.TweenTarget) => {
    gsap.fromTo(element, {}, {
      delay,
      opacity: 1,
      ease: 'power2.out',
      stagger: {
        amount: stagger
      }
    });
  }, [delay]);

  const fadeOut = useCallback((element: gsap.TweenTarget) => {
    gsap.fromTo(element, {}, {
      opacity: 0,
      ease: 'power2.out'
    });
  }, []);

  const slideIn = useCallback((element: gsap.TweenTarget) => {
    gsap.fromTo(element, {}, {
      delay,
      opacity: 1,
      x: 0,
      ease: 'power2.out',
      stagger: {
        amount: stagger
      }
    });
  }, [delay]);

  const slideOut = useCallback((element: gsap.TweenTarget) => {
    gsap.fromTo(element, {}, {
      opacity: 0,
      x: -100,
      ease: 'power2.out'
    });
  }, []);

  useEffect(() => {
    let animate: (element: gsap.TweenTarget) => void = () => { // emty
    };

    if (animationDir === AnimationDir.in) {
      switch(animation) {
      case AppearAnimation.fadeIn:
        animate = fadeIn;
        break;
      case AppearAnimation.fadeInBottom:
        animate = fadeInBottom;
        break;
      case AppearAnimation.slideIn:
        animate = slideIn;
        break;
      case AppearAnimation.countIn:
        animate = countIn;
        break;
      }
    } else if (animationDir === AnimationDir.out) {
      switch(animation) {
      case AppearAnimation.fadeIn:
        animate = fadeOut;
        break;
      case AppearAnimation.fadeInBottom:
        animate = fadeOutBottom;
        break;
      case AppearAnimation.slideIn:
        animate = slideOut;
        break;
      }
    }

    animate(`.${componentUid}-${animation}`);
  }, [animationDir, desktopAnimation, mobileAnimation]);

  return (
    <StyledAppearOnScrollTarget
      ref={targetRef}
      className={`${componentUid}-${animation}`}>
      {children}
    </StyledAppearOnScrollTarget>
  );
};
