import { all, put, takeEvery } from 'redux-saga/effects';
import { BigNumber, FixedNumber } from 'ethers';
import { PayloadAction } from '@reduxjs/toolkit';

import { actions } from '@/common/store/tokenSlice';
import TokenProvider from '@utils/contractProviders/token/TokenProvider';

function* getBalance(action: PayloadAction<string | null>): Generator {
  const userAddress = action.payload;
  if (!userAddress) {
    return;
  }

  try {
    const balance = (yield TokenProvider.balanceOf(userAddress)) as { _hex: string, _isBigNumber: boolean };

    if (balance instanceof BigNumber) {
      const fixedBalance = FixedNumber.fromValue(balance, 18, 'fixed');
      yield put(actions.setBalance(fixedBalance._value));
    }
  } catch (error) {
    yield put(actions.setError(error));
  }
}
export default function* TokenSaga(): Generator {
  yield all([
    takeEvery([actions.getBalance], getBalance),
  ]);
}
