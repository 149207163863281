import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootSaga from '../saga/index';
import tokenSlice from './tokenSlice';
import walletSlice from './walletSlice';
import presaleSlice from './presaleSlice';
import vestingSlice from './vestingSlice';
import homePageSlice from './homePageSlice';
import domainSlice from './domainSlice';
import notificationSlice from './notificationSlice';
import referralSlice from './referralSlice';

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  sagaMiddleware
];

const configureStoreLazy = () => {
  const store = configureStore({
    reducer: {
      token: tokenSlice,
      wallet: walletSlice,
      presale: presaleSlice,
      vesting: vestingSlice,
      homePage: homePageSlice,
      domain: domainSlice,
      notification: notificationSlice,
      referral: referralSlice
    },
    middleware
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

type StoreType = ReturnType<typeof configureStoreLazy>;
export type RootState = ReturnType<StoreType['getState']>;

export default configureStoreLazy;
