import React from 'react';
import { StyledTooltip, StyledTooltipText } from './Tooltip.styles';

export interface TooltipProps {
  text?: string;
  disabled?: boolean;
  disableHover?: boolean;
}

const Tooltip = ({ children, text, disabled, disableHover }: React.PropsWithChildren<TooltipProps>) => {
  return (
    <StyledTooltip disabled={disabled} disableHover={disableHover}>
      {children}
      <StyledTooltipText>
        {text}
      </StyledTooltipText>
    </StyledTooltip>
  );
};

export default Tooltip;
