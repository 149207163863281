import { BigNumber, Contract, FixedNumber } from 'ethers';

import FactoryVestingControllerProvider
  from '@utils/contractProviders/factoryVestingController/FactoryVestingControllerProvider';
import TokenProvider from '@utils/contractProviders/token/TokenProvider';
import VestingContractProvider from '@utils/contractProviders/vestingContract/VestingContractProvider';

export type VestingContracts = {
  address: string;
  availableToWithdraw: number;
  withdrawnValue: number;
  balance: number;
}

type BigNumberObject = {
  _hex: string;
  _isBigNumber: boolean
};

export class VestingService {
  private readonly walletAddress: string;
  private vestingContracts: VestingContracts[] = [];

  constructor(userAddress: string) {
    this.walletAddress = userAddress;
  }

  async getVestingData() {
    await this.prepareVestingContractsAsync();

    return this.vestingContracts;
  }

  private async prepareVestingContractsAsync() {
    const contracts = [
      FactoryVestingControllerProvider
    ];

    await Promise.all(contracts.map(async (contract) => {
      const hasVestingContract = await contract.hasVestingContract(this.walletAddress);

      if (hasVestingContract) {
        const vestingContract = await contract.vestingContract(this.walletAddress);
        const balance = await VestingService.getBalance(vestingContract);

        const vestingContractProvider = VestingContractProvider(vestingContract); // Final vesting contract

        const availableToWithdraw = await VestingService.amountAvailableToWithdraw(vestingContractProvider);
        const withdrawnValue = await VestingService.withdrawnValue(vestingContractProvider);

        this.vestingContracts.push({
          address: vestingContract,
          availableToWithdraw,
          withdrawnValue,
          balance
        });
      }
    }));
  }

  private static async amountAvailableToWithdraw(vestingContractProvider: Contract) {
    try {
      const amountAvailableToWithdraw = await vestingContractProvider.amountAvailableToWithdraw();
      return  VestingService.getValue(amountAvailableToWithdraw);
    } catch (error) {
      return 0;
    }
  }

  private static async withdrawnValue(vestingContractProvider: Contract) {
    const withdrawn = await vestingContractProvider.withdrawn();
    return VestingService.getValue(withdrawn);
  }

  private static async getBalance(contractAddress: string) {
    const balance = await TokenProvider.balanceOf(contractAddress);
    if (balance instanceof BigNumber) {
      const fixedBalance = FixedNumber.fromValue(balance, 18, 'fixed');
      return Number(fixedBalance._value);
    }
    return 0;
  }

  private static getValue(bgNumber: BigNumberObject) {
    if (bgNumber instanceof BigNumber) {
      const fixedBalance = FixedNumber.fromValue(bgNumber, 18, 'fixed');
      return Number(fixedBalance._value);
    }
    return 0;
  }
}
