import { FontNames } from '@common/styles/fontNames';
import { MEDIA_QUERIES } from '@common/styles/media';
import { ReactComponent as ArrowRightSvg } from '@img/arrow.svg';
import styled from 'styled-components';

export interface StyledButtonProps {
  loading?: boolean;
}

export const StyledButton = styled.a<StyledButtonProps>`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 14px 60px 10px 60px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.mineShaft};
  font-family: ${FontNames.graphikLight};
  font-size: 0.9rem;
  margin: 10px 0;
  text-transform: uppercase;

  ${MEDIA_QUERIES.MOBILE} {
    font-size: max(4vw, 13px);
  }
`;

export const StyledArrow = styled(ArrowRightSvg)`
  fill: white;
  width: 25px;
  position: absolute;
  right: 8px;
  padding-bottom: 4px;
`;
