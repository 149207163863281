import { HomeSections, RouteNames } from '@/common/constants';
import { domainActions, domainSelectors } from '@/common/store/domainSlice';
import { homePageActions, homePageSelectors } from '@/common/store/homePageSlice';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';
import { StyledNavItem, StyledNavLogo, StyledLogo, StyledNavItems, StyledCenteredAroundItems } from './NavItems.styles';

export interface NavItemsProps {
  mobile?: boolean;
}

const NavItems = ({ mobile }: NavItemsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobileNavOpen = useSelector(domainSelectors.isMobileNavOpen);
  const section = useSelector(homePageSelectors.section);
  const location = useLocation();
  const navigate = useNavigate();

  const handleItemClicked = (newHash: HomeSections) => {
    const isDifferentPathThanHome = `/${location.pathname}` !== RouteNames.home;

    if (isDifferentPathThanHome) {
      navigate(RouteNames.home);
    }
    if (isMobileNavOpen) {
      dispatch(domainActions.setIsMobileNavOpen(!isMobileNavOpen));
    }
    setTimeout(() => {
      dispatch(homePageActions.setNavigateToSection(newHash));
    }, 2);
  };

  const isActive = (hash: HomeSections) => section === hash && location.pathname === RouteNames.home;

  return (
    <StyledNavItems>
      <StyledCenteredAroundItems>
        <StyledNavItem
          active={isActive(HomeSections.mission)}
          onClick={() => handleItemClicked(HomeSections.mission)}>
          {t('header_nav_item_mission')}
        </StyledNavItem>
        <StyledNavItem
          active={isActive(HomeSections.market)}
          onClick={() => handleItemClicked(HomeSections.market)}>
          {t('header_nav_item_market')}
        </StyledNavItem>
        <StyledNavItem
          active={location.pathname === RouteNames.vesting}
          href={RouteNames.vesting}>
          Vesting
        </StyledNavItem>
      </StyledCenteredAroundItems>
      {!mobile && <StyledNavLogo
        onClick={() => handleItemClicked(HomeSections.home)}
      >
        <StyledLogo />
      </StyledNavLogo>}
      <StyledCenteredAroundItems>
        <StyledNavItem
          active={isActive(HomeSections.product)}
          onClick={() => handleItemClicked(HomeSections.product)}>
          {t('header_nav_item_product')}
        </StyledNavItem>
        <StyledNavItem
          onClick={() => handleItemClicked(HomeSections.businessModel)}
          active={isActive(HomeSections.businessModel)}
        >
          {t('header_nav_item_business_model')}
        </StyledNavItem>
        <StyledNavItem
          active={isActive(HomeSections.token)}
          onClick={() => handleItemClicked(HomeSections.token)}>
          {t('header_nav_item_token')}
        </StyledNavItem>
      </StyledCenteredAroundItems>
    </StyledNavItems>
  );
};

export default memo(NavItems);
