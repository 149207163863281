import {
  StyledNotification,
  StyledNotificationContent,
  StyledNotificationMessage,
  StyledNotificationMessageHeaderWrapper,
  StyledNotificationMessageLine
} from './Notification.styles';
import { actions, NotificationType } from '@/common/store/notificationSlice';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

export interface NotificationProps {
  notification: NotificationType;
}

const Notification = ({ notification }: NotificationProps) => {
  const dispatch = useDispatch();
  const selfRemoveRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    selfRemoveRef.current = setTimeout(() => {
      dispatch(actions.removeNotification(notification.id));
    }, notification.duration);

    return () => {
      clearTimeout(selfRemoveRef.current);
    };
  }, [dispatch, notification]);

  return (
    <StyledNotification>
      <StyledNotificationContent>
        <StyledNotificationMessageHeaderWrapper type={notification.variant}>
          <div>
            {notification.header}
          </div>
        </StyledNotificationMessageHeaderWrapper>
        <StyledNotificationMessageLine type={notification.variant} />
        <StyledNotificationMessage>
          {notification.message}
        </StyledNotificationMessage>
      </StyledNotificationContent>
    </StyledNotification>
  );
};

export default Notification;
