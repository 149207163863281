import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './index';

export interface DomainSliceInstance {
  isMobileNavOpen: boolean;
}

const initialState: DomainSliceInstance = {
  isMobileNavOpen: false,
};

const reducers = {
  setIsMobileNavOpen: (state: DomainSliceInstance, action: PayloadAction<boolean>) => {
    state.isMobileNavOpen = action.payload;
  },
};

export const domainSelectors = {
  isMobileNavOpen: (state: RootState): boolean => state.domain.isMobileNavOpen,
};

export const domainSlice = createSlice({
  name: 'domain',
  initialState,
  reducers
});

type DomainSliceActionsType = typeof domainSlice.actions;

export const domainActions = {
  ...domainSlice.actions
} as DomainSliceActionsType;

export default domainSlice.reducer;
