import { FontNames } from '@/common/styles/fontNames';
import styled, { css } from 'styled-components';

export const StyledInput = styled.div`
  width: 90%;
  margin: 15px 15px 0 15px;
  position: relative;
`;

export interface StyledTextInputProps {
  error?: boolean;
}

export const StyledTextInput = styled.input<StyledTextInputProps>`
  padding: 10px 15px;
  border-radius: 10px;
  width: 100%;
  font-family: ${FontNames.graphikMedium};
  border: 1px solid ${({ theme }) => theme.colors.silver};
  background-color: ${({ theme }) => theme.colors.gallery};

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.nobel};
  }

  ${(props) => props.error && css`
    color: ${({ theme }) => theme.colors.error};
    border: 1px solid ${({ theme }) => theme.colors.error};

    :focus {
      border: 1px solid ${({ theme }) => theme.colors.error};
    }

    ::placeholder {
      color: ${({ theme }) => theme.colors.error};
      opacity: 0.8;
    }
  `}
`;
