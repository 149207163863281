import { RouteNames } from '@/common/constants';
import { homePageSelectors } from '@/common/store/homePageSlice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const UseScrollToTopDuringNavigation = () => {
  const location = useLocation();
  const navigateToSection = useSelector(homePageSelectors.navigateToSection);

  useEffect(() => {
    if (location.pathname === RouteNames.home || navigateToSection) {
      return;
    }
    window.scrollTo(0, 0);
  }, [location, navigateToSection]);
};

export default UseScrollToTopDuringNavigation;
