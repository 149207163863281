import styled, { css } from 'styled-components';
import { MEDIA_QUERIES } from '@common/styles/media';

export const MOBILE_NAV_HEIGHT = 60;

export interface StyledHeaderProps {
  isNavBarFixed: boolean;
}

export const StyledHeader = styled.header<StyledHeaderProps>`
  z-index: 2;
  position: fixed;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 72px;
  transition: all 0.5s;

  ${MEDIA_QUERIES.TABLET} {
    height: 60px;
  }

  ${({ isNavBarFixed }) => isNavBarFixed && css`
    height: 60px;
    background-color: ${({ theme }) => theme.colors.rioGrande};
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
  `}
`;

export const StyledHeaderContent = styled.div`
  max-width: 1400px;
  width: 100%;
  height: 100%;
  position: absolute;
`;
