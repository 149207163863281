import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './index';

interface TokenSliceInstance {
  balance: string | null;
  vestedBalance: string | null;
  error?: unknown;
}

const initialState: TokenSliceInstance = {
  balance: null,
  vestedBalance: null
};

const reducers = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getBalance: (state: TokenSliceInstance, action: PayloadAction<string | null>) => {},
  setBalance: (state: TokenSliceInstance, action: PayloadAction<string>) => {
    state.balance = action.payload;
  },
  setVestedBalance: (state: TokenSliceInstance, action: PayloadAction<string>) => {
    state.vestedBalance = action.payload;
  },
  setError: (state: TokenSliceInstance, action: PayloadAction<unknown>) => {
    state.error = action.payload;
  },
};

export const selectors = {
  tokenData: (state: RootState): TokenSliceInstance => state.token,
};

export const tokenSlice = createSlice({
  name: 'application',
  initialState,
  reducers
});
type TokenSliceActionsType = typeof tokenSlice.actions;

export const actions = {
  ...tokenSlice.actions
} as TokenSliceActionsType;

export default tokenSlice.reducer;
