import { FontNames } from '@/common/styles/fontNames';
import { MEDIA_QUERIES } from '@/common/styles/media';
import styled from 'styled-components';

export const StyledTokenProperty = styled.div`
  font-family: ${FontNames.graphikMedium};
  font-size: 1.2rem;
  width: 100%;

  ${MEDIA_QUERIES.MOBILE} {
    font-size: 1rem;
  }
`;

export const StyledTokenPropertyName = styled.h2`
  color: ${({ theme }) => theme.colors.wildSand};
  font-size: 1em;
  margin: 0;
  padding: 0 3px;
`;

export const StyledTokenPropertyValue = styled.p`
  color: ${({ theme }) => theme.colors.rioGrande};
  font-size: 1em;
  margin: 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 10px;
  padding: 0 3px;
  width: fit-content;

  ${MEDIA_QUERIES.MOBILE} {
    max-width: 60vw;
  }

  :hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.1);
  }
`;
