import { ChangeEventHandler } from 'react';
import ErrorMessage from './ErrorMessage/ErrorMessage';
import { StyledInput, StyledTextInput } from './TextInput.styles';

export interface TextInput {
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  error?: string;
}

const TextInput = ({ placeholder, onChange, value, error }: TextInput): JSX.Element => {
  return (
    <StyledInput>
      <StyledTextInput onChange={onChange} value={value} placeholder={placeholder} error={!!error} />
      <ErrorMessage message={error} />
    </StyledInput>
  );
};

export default TextInput;
