import { StyledArrow, StyledButton } from './Button.styles';

export interface ButtonProps {
  thinText?: string;
  href?: string;
  target?: string;
  arrow?: boolean;
  onClick?: () => void;
  className?: string;
  loading?: boolean;
}

const Button = ({ 
  thinText, 
  href, 
  target, 
  onClick, 
  arrow, 
  className, 
  loading 
}: ButtonProps): JSX.Element => {
  return (
    <StyledButton 
      loading={loading}
      target={target} 
      href={href} 
      onClick={onClick} 
      className={className}
    >
      {thinText}
      {arrow && <StyledArrow />}
    </StyledButton>
  );
};

export default Button;
