import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { addressValidationRegex, emailValidationRegex } from '@/common/constants';
import { selectors, actions } from '@/common/store/referralSlice';
import TextInput from '@/shared/TextInput/TextInput';
import { StyledRefLink, StyledRefLinkForm, StyledRefLinkFormHeader, StyledSubmitButton } from './RefLinkForm.styles';

const RefLinkForm = (): JSX.Element => {
  const dispatch = useDispatch();
  const refLink = useSelector(selectors.referralHash);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [address, setAddress] = useState('');
  const { t } = useTranslation();

  const generateReflink = () => {
    setLoading(true);
    setEmailError('');
    setAddressError('');

    const emailRegex = new RegExp(emailValidationRegex);
    const addressRegex = new RegExp(addressValidationRegex);

    if (!addressRegex.test(address)) {
      setAddressError(t('help_us_grow_form_error_address'));
      setLoading(false);
      return;
    }

    if (!emailRegex.test(email)) {
      setEmailError(t('help_us_grow_form_error_email'));
      setLoading(false);
      return;
    }

    dispatch(actions.createOrGetReferralHash({
      address,
      email
    }));

    setLoading(false);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target) {
      return;
    }
    setEmailError('');
    setEmail(event.target.value);
  };

  const handleAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target) {
      return;
    }
    setAddressError('');
    setAddress(event.target.value);
  };

  return (
    <StyledRefLinkForm loading={loading}>
      {refLink ?
        (
          <>
            <StyledRefLinkFormHeader>{t('help_us_grow_form_result')}</StyledRefLinkFormHeader>
            <StyledRefLink>{refLink}</StyledRefLink>
            <StyledRefLink>{`https://polaron.ag/presale?ref=${refLink}`}</StyledRefLink>
          </>
        )
        :
        (
          <>
            <StyledRefLinkFormHeader>{t('help_us_grow_form_test')}</StyledRefLinkFormHeader>
            <TextInput
              onChange={handleAddressChange}
              error={addressError}
              value={address}
              placeholder={t('help_us_grow_form_address_placeholder')}
            />
            <TextInput
              onChange={handleEmailChange}
              error={emailError}
              value={email}
              placeholder={t('help_us_grow_form_email_placeholder')}
            />
            <StyledSubmitButton
              arrow
              onClick={generateReflink}
              thinText = {t('help_us_grow_form_submit')}
            />
          </>
        )}
    </StyledRefLinkForm>
  );
};

export default RefLinkForm;
