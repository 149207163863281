import styled from 'styled-components';
import { ReactComponent as FooterSvg } from '@img/footer-bottom-image.svg';
import { ReactComponent as FooterMobileSvg } from '@img/footer-bottom-mobile-image.svg';
import { ReactComponent as StickSvg } from '@img/stick-background-black.svg';
import { FontNames } from '@common/styles/fontNames';
import { MEDIA_QUERIES } from '@common/styles/media';
import { Link } from 'react-router-dom';

export const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.colors.rioGrande};
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto min-content min-content;
`;

export const StyledFooterContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 180px 0;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.rioGrande};
`;

export const StyledFooterText = styled.h2`
  font-size: 1.6em;
  text-align: center;
  line-height: 30px;
  width: 500px;
  font-family: ${FontNames.sharpGroteskLight};
`;

export const StyledFooterTextBold = styled.span`
  font-family: ${FontNames.sharpGroteskMedium};
`;

export const StyledFooterBackground = styled(StickSvg)`
  position: absolute;
  width: 40vw;
`;

export const StyledFooterLinksDesktop = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  text-align: center;
  max-width: 1300px;
  margin: 0 auto;
  font-size: 0.8rem;

  ${MEDIA_QUERIES.MOBILE} {
    display: none;
  }
`;

export const StyledFooterLinksMobile = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 0.8rem;
  padding-bottom: 25px;

  ${MEDIA_QUERIES.MOBILE} {
    display: flex;
  }
`;

export const StyledFooterLink = styled(Link)`
  text-transform: uppercase;
  font-family: ${FontNames.graphikMedium};
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.2s;

  :hover {
    color: white;
  }

  ${MEDIA_QUERIES.MOBILE} {
    margin: 10px 0;
  }
`;

export const StyledCopyRights = styled.p`
  font-family: ${FontNames.graphikLight};
  text-align: center;
  text-transform: uppercase;  
  ${MEDIA_QUERIES.MOBILE} {
    margin: 25px 0;
  }
`;

export const StyledFooterBottomDesktopImg = styled(FooterSvg)`
  width: 99vw;
  height: min-content;
  margin-top: 20px;
  margin-bottom: -.8vw;

  ${MEDIA_QUERIES.MOBILE} {
    display: none;
  }
`;

export const StyledFooterBottomMobileImg = styled(FooterMobileSvg)`
  display: none;
  margin: 0 auto;
  max-width: 90vw;

  ${MEDIA_QUERIES.MOBILE} {
    display: block;
  }
`;
