
import styled from 'styled-components';
import { ReactComponent as TwitterSvg } from '@img/twitter.svg';
import { ReactComponent as FacebookSvg } from '@img/facebook.svg';
import { ReactComponent as TelegramSvg } from '@img/telegram.svg';
import { ReactComponent as YoutubeSvg } from '@img/youtube.svg';
import { MEDIA_QUERIES } from '@/common/styles/media';

export const StyledSocialMedia = styled.div`
position: absolute;
right: 15px;

${MEDIA_QUERIES.TABLET} {
  position: relative;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}
`;

export const StyledSocialMediaItem = styled.a`
margin: 0 3px;
cursor: pointer;
color: white;

svg {
  transition: all 0.3s;
  fill: ${({ theme }) => theme.colors.heavyMetal};

  :hover {
    fill: ${({ theme }) => theme.colors.white};
  }
}

${MEDIA_QUERIES.TABLET} {
  margin: 6px;
}
`;

export const StyledTwitterIcon = styled(TwitterSvg)``;

export const StyledFacebookIcon = styled(FacebookSvg)``;

export const StyledTelegramIcon = styled(TelegramSvg)``;

export const StyledYoutubeIcon = styled(YoutubeSvg)`
transform: translateY(5px);
`;
