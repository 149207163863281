import { FontNames } from '@/common/styles/fontNames';
import { MEDIA_QUERIES } from '@/common/styles/media';
import styled from 'styled-components';

export const StyledHelpUsGrow = styled.div`
  font-size: 1rem;
  padding: 120px 10px;
  background-color: ${({ theme }) => theme.colors.wildSand};
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;  
  color: ${({ theme }) => theme.colors.heavyMetal};
`;

export const StyledHorizontalLine = styled.hr`
  width: 70vw;
  max-width: 600px;
  color: ${({ theme }) => theme.colors.nobel};
  margin: 30px 0 60px 0;
`;

export const StyledHelpUsGrowHeader = styled.h2`
  font-family: ${FontNames.sharpGroteskMedium};
  text-align: center;
`;

export const StyledHelpUsGrowHeaderBold = styled.span`
  font-size: 2.5em;
  font-family: ${FontNames.sharpGroteskMedium};
`;

export const StyledHelpUsGrowHeaderDesc = styled.p`
  font-family: ${FontNames.graphikLight};
  max-width: 460px;
  text-align: center;
  margin: 40px 0;

  ${MEDIA_QUERIES.MOBILE} {
    max-width: 90vw;
  }
`;
