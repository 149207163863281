import { MEDIA_QUERIES } from '@/common/styles/media';
import styled, { css, keyframes } from 'styled-components';
import { MOBILE_NAV_HEIGHT } from '../Header.styles';
import { ReactComponent as LogoMobileSvg } from '@img/logo-mobile.svg';

export const MOBILE_NAV_SHOW_UP_TIME_MS = 300;

export const StyledMobileNav = styled.div`
  display: none;
  width: 100%;
  height: ${MOBILE_NAV_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.rioGrande};
  grid-template-columns: 70px auto 70px;

  ${MEDIA_QUERIES.TABLET} {
    display: grid;
  }
`;

export interface StyledMobileNavProps {
  isOpen?: boolean;
}

export const StyledMobileNavlogo = styled(LogoMobileSvg)<StyledMobileNavProps>`
  margin: auto auto;
`;

export const StyledMobileNavContent = styled.div<StyledMobileNavProps>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.rioGrande};
  width: 100vw;
  height: 100vh;
  transition: opacity 300ms;
  
  ${({ isOpen }) => isOpen
    ? css`
        transition-delay: ${MOBILE_NAV_SHOW_UP_TIME_MS}ms;
        opacity: 1;
      ` : css`
        pointer-events: none;
        opacity: 0;
      ` }
`;

const showUpCloseNavButtonAnimation = keyframes`
  60% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
`;

export const StyledCloseNavContentButton = styled.button<StyledMobileNavProps>`
  opacity: 0;
  width: 30px;
  height: 30px;
  margin: 16px 0 0 21px;
  background-color: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  animation: ${showUpCloseNavButtonAnimation};
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 200ms;
  animation-delay: 0.75s;
  animation-play-state: ${({ isOpen }) => isOpen ? 'running' : 'paused'};

  ::before,
  ::after {
    content: '';
    display: block;
    position: absolute;
    width: 26px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.black};
    transform-origin: center;
  }

  ::before {
    transform: rotate(-45deg);
  }

  ::after {
    transform: rotate(45deg);
  }
`;
