import { all, put, takeEvery } from 'redux-saga/effects';
import { BigNumber, Contract, ethers, FixedNumber, Signer } from 'ethers';
import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';

import { actions as presaleActions, VestedInformationProps } from '@/common/store/presaleSlice';
import { actions as tokenActions } from '@/common/store/tokenSlice';
import PrivateVestingSignerControllerProvider
  from '@utils/contractProviders/privateVestingController/PrivateVestingSignerControllerProvider';
import VestingSignerControllerProvider
  from '@utils/contractProviders/vestingController/VestingSignerControllerProvider';
import TokenProvider from '@utils/contractProviders/token/TokenProvider';

// We change contract in this function -> now it presents vesting controller without white list
function* whiteListedPresaleSwap(action: PayloadAction<{ quantityOfBNB: number, signer: Signer, referralCode: string | null }>): Generator {
  const { quantityOfBNB, signer, referralCode } = action.payload;
  if (!quantityOfBNB || !signer) {
    return;
  }

  let internalTransactionId = null;

  if (referralCode && referralCode !== '') {
    try {
      const result = (yield axios.post(`${process.env.REACT_APP_API_BASE_PATH}/reference/initial-transaction`, {
        refHash: referralCode
      })) as AxiosResponse<{ transactionId: string; }>;

      internalTransactionId = result.data.transactionId;
    } catch (e) {
      // We cannot stop transaction
    }
  }

  // If you want to do some test swap functionality,
  // please change address of token contact (and do not commit your changes)
  try {
    const eventMessage = referralCode ? referralCode : '-';
    const value = ethers.utils.parseEther(quantityOfBNB.toString());
    const presaleSignerProvider = VestingSignerControllerProvider(signer);

    const result = (yield presaleSignerProvider.buy(eventMessage, { value: value })) as any;
    const callback = (yield result.wait()) as any;
    const signerAddress = (yield signer.getAddress()) as string | undefined;

    if (callback && signerAddress) {
      yield put(tokenActions.getBalance(signerAddress));
    }

    const transactionHash = callback.transactionHash;

    if (internalTransactionId) {
      yield axios.put(`${process.env.REACT_APP_API_BASE_PATH}/reference/initial-transaction`, {
        transactionId: internalTransactionId,
        customerAddress: signerAddress,
        transactionHash: transactionHash,
        amount: quantityOfBNB,
      });
    }
  } catch (error: any) {
    yield put(presaleActions.setError(error));
  }
}

function* privatePresaleSwap(action: PayloadAction<{
  quantityOfBNB: number,
  signer: Signer,
  referralHash?: string
}>): Generator {
  const { quantityOfBNB, signer } = action.payload;
  if (!quantityOfBNB || !signer) {
    return;
  }

  // If you want to do some test swap functionality,
  // please change address of token contact (and do not commit your changes)
  try {
    const value = ethers.utils.parseEther(quantityOfBNB.toString());
    const presaleSignerProvider = PrivateVestingSignerControllerProvider(signer);

    const result = (yield presaleSignerProvider.buy({ value: value })) as any;
    const callback = yield result.wait();

    // yield put(notificationActions.pushSuccessNotification({
    //   header: 'Transaction successful',
    //   message: 'Token swap completed'
    // }));

    const signerAddress = (yield signer.getAddress()) as string | undefined;

    if (callback && signerAddress) {
      yield put(tokenActions.getBalance(signerAddress));
    }

  } catch (error: any) {
    const message = error?.error?.message;
    if (message) {
      // yield put(notificationActions.pushErrorNotification({
      //   header: 'Transaction failed',
      //   message
      // }));
    }

    yield put(presaleActions.setError(error));
  }
}

function* getTokenPrice(action: PayloadAction<{ presaleProvider: Contract }>): Generator {
  const { presaleProvider } = action.payload;
  if (!presaleProvider) {
    return 0;
  }

  try {
    const result = (yield presaleProvider.priceForOneToken()) as {_hex: string, _isBigNumber: boolean};
    if (result instanceof BigNumber) {
      const tokenPrice = FixedNumber.fromValue(result, 18, 'fixed')._value;
      const parsedTokenPrice = parseFloat(tokenPrice);

      yield put(presaleActions.setTokenPrice(parsedTokenPrice));
    }
  } catch (error) {
    yield put(presaleActions.setError(error));
  }
}

function* getVestedContractInformation(action: PayloadAction<VestedInformationProps>): Generator {
  const { presaleProvider, address } = action.payload;
  if (!presaleProvider) {
    return;
  }

  try {
    const vestedContractAddress = (yield presaleProvider.vestingContract(address)) as string;

    const balance = (yield TokenProvider.balanceOf(vestedContractAddress)) as { _hex: string, _isBigNumber: boolean };

    if (balance instanceof BigNumber) {
      const fixedBalance = FixedNumber.fromValue(balance, 18, 'fixed');
      yield put(tokenActions.setVestedBalance(fixedBalance._value));
    }
  } catch (error) {
    yield put(presaleActions.setError(error));
  }
}

export default function* PresaleSaga(): Generator {
  yield all([
    takeEvery([presaleActions.whiteListedPresaleSwap], whiteListedPresaleSwap),
    takeEvery([presaleActions.privatePresaleSwap], privatePresaleSwap),
    takeEvery([presaleActions.getTokenPrice], getTokenPrice),
    takeEvery([presaleActions.getVestedContractInformation], getVestedContractInformation),
  ]);
}
