import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contract, Signer } from 'ethers';

import { RootState } from './index';

export type VestedInformationProps = {
  presaleProvider: Contract;
  address: string;
};

interface PresaleSliceInstance {
  tokenPrice: number | null;
  error?: unknown;
}

const initialState: PresaleSliceInstance = {
  tokenPrice: null
};

const reducers = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  whiteListedPresaleSwap: (state: PresaleSliceInstance, action: PayloadAction<{ quantityOfBNB: number, signer: Signer, referralCode: string | null }>) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  privatePresaleSwap: (state: PresaleSliceInstance, action: PayloadAction<{ quantityOfBNB: number, signer: Signer, referralHash?: string }>) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getTokenPrice: (state: PresaleSliceInstance, action: PayloadAction<{ presaleProvider: Contract }>) => {},
  setTokenPrice: (state: PresaleSliceInstance, action: PayloadAction<number>) => {
    state.tokenPrice = action.payload;
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getVestedContractInformation: (state: PresaleSliceInstance, action: PayloadAction<VestedInformationProps>) => {},
  setError: (state: PresaleSliceInstance, action: PayloadAction<unknown>) => {
    state.error = action.payload;
  },
};

export const selectors = {
  tokenPrice: (state: RootState): number | null => state.presale.tokenPrice,
};

export const presaleSlice = createSlice({
  name: 'presale',
  initialState,
  reducers
});
type PresaleSliceActionsType = typeof presaleSlice.actions;

export const actions = {
  ...presaleSlice.actions
} as PresaleSliceActionsType;

export default presaleSlice.reducer;
