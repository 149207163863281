import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Signer } from 'ethers';

import { RootState } from './index';
import { VestingContracts } from '@services/vesting/VestingService';

export interface VestingSliceInstance {
  vestingData: VestingContracts[];
  selectedVestingData?: VestingContracts;
}

const initialState: VestingSliceInstance = {
  vestingData: []
};

const reducers = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getVestingData: (_: VestingSliceInstance, action: PayloadAction<{ address: string }>) => {},
  setVestingData: (state: VestingSliceInstance, action: PayloadAction<VestingContracts[]>) => {
    const vestingData = action.payload;
    state.vestingData = vestingData;

    if (vestingData && Array.isArray(vestingData)) {
      state.selectedVestingData = vestingData[0];
    }
  },
  setSelectedVestingData: (state: VestingSliceInstance, action: PayloadAction<{ address: string }>) => {
    const { address } = action.payload;

    state.selectedVestingData = state.vestingData.find(contract => contract.address === address);
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  withdrawAvailable: (_: VestingSliceInstance, action: PayloadAction<{ address: string, signer: Signer }>) => {},
};

export const selectors = {
  vestingData: (state: RootState): VestingContracts[] => state.vesting.vestingData,
  selectedVestingData: (state: RootState): VestingContracts | undefined => state.vesting?.selectedVestingData,
};

export const vestingSlice = createSlice({
  name: 'vesting',
  initialState,
  reducers
});
type VestingSliceActionsType = typeof vestingSlice.actions;

export const actions = {
  ...vestingSlice.actions
} as VestingSliceActionsType;

export default vestingSlice.reducer;
