import { isEmpty } from 'lodash';

const APP_STORAGE_PREFIX = 'polaron';

export class PersistentStorage<T> {
  private readonly appKey: string;

  constructor(key: string) {
    this.appKey = this.createKey(key);
  }

  serialize = (data?: T) => {
    localStorage.setItem(this.appKey, JSON.stringify(data));
  };

  deserialize = (): T | undefined => {
    const serializedData = localStorage.getItem(this.appKey) || undefined;
    if (!serializedData) {
      return undefined;
    }
    const serializedObject = JSON.parse(serializedData);
    if (isEmpty(serializedObject) && typeof serializedObject !== 'boolean') {
      return undefined;
    }
    return serializedObject;
  };

  clear = () => {
    this.serialize();
  };

  private createKey = (key: string) => {
    return `${APP_STORAGE_PREFIX}.${key}`;
  };
}
