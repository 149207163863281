import { useTranslation } from 'react-i18next';
import { 
  StyledDownloadLogoForm, 
  StyledDownloadButton, 
  StyledSmile, 
  StyledDescription 
} from './DownloadLogoForm.styles';

const DownloadLogoForm = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StyledDownloadLogoForm>
      <StyledSmile />
      <StyledDescription>
        {t('download_logo_text')}
      </StyledDescription>
      <StyledDownloadButton
        arrow
        thinText = {t('download_logo_button')}
        href={'./static/Logo.zip'}
      />
    </StyledDownloadLogoForm>
  );
};

export default DownloadLogoForm;
