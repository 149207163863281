export const MAX_APP_WIDTH = 1280;

export enum RouteNames {
  home = '/',
  news = '/news',
  privacyPolicy = '/privacy-policy',
  termsAndConditions = '/terms-and-conditions',
  privatePresale = '/presale-yvpT3JTQdxOy8dtYOBbz9w',
  whiteListedPresale = '/presale',
  vesting = '/vesting',
  helpUsGrow = '/help-us-grow'
}

export enum HomeSections {
  home = '#home',
  mission = '#mission',
  challenges = '#challenges',
  market = '#market',
  product = '#product',
  businessModel = '#business-model',
  token = '#token',
  vesting = '#vesting',
}

export enum LanguagesEnum {
  pl = 'pl',
  en = 'en',
  es = 'es'
}

export const emailValidationRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const addressValidationRegex = /^0x[a-fA-F0-9]{40}$/g;
