import { HomeSections, RouteNames } from '@/common/constants';
import { domainActions, domainSelectors } from '@/common/store/domainSlice';
import { homePageActions } from '@/common/store/homePageSlice';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Hamburger from '../Hamburger/Hamburger';
import Languages from '../Languages/Languages';
import NavItems from '../NavItems/NavItems';
import SocialMedia from '../SocialMedia/SocialMedia';
import { 
  StyledMobileNav, 
  StyledMobileNavlogo, 
  StyledMobileNavContent, 
  StyledCloseNavContentButton 
} from './MobileNav.styles';
import UseCloseMobileNavOnDesktop from './UseCloseMobileNavOnDesktop';

const MobileNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileNavOpen = useSelector(domainSelectors.isMobileNavOpen);

  UseCloseMobileNavOnDesktop();

  const setIsMobileNavOpen = useCallback((isOpen: boolean) => {
    dispatch(domainActions.setIsMobileNavOpen(isOpen));
  }, [dispatch]);

  const navigateHome = useCallback(() => {
    setIsMobileNavOpen(false);
    const isDifferentPathThanHome = `/${location.pathname}` !== RouteNames.home;
    
    if (isDifferentPathThanHome) {
      navigate(RouteNames.home);
    }
    setTimeout(() => {
      dispatch(homePageActions.setNavigateToSection(HomeSections.home));
    }, 2);
  }, [setIsMobileNavOpen, dispatch]);

  return (
    <StyledMobileNav>
      <Hamburger onClick={() => setIsMobileNavOpen(true)} isOpen={isMobileNavOpen} />
      <StyledMobileNavlogo onClick={navigateHome} />
      <StyledMobileNavContent isOpen={isMobileNavOpen}>
        <StyledMobileNav>
          <StyledCloseNavContentButton onClick={() => setIsMobileNavOpen(false)} isOpen={isMobileNavOpen} />
          <StyledMobileNavlogo onClick={navigateHome} />
        </StyledMobileNav>
        <NavItems mobile />
        <SocialMedia />
        <Languages />
      </StyledMobileNavContent>
    </StyledMobileNav>
  );
};

export default MobileNav;
