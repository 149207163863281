import { RouteNames } from '@/common/constants';
import { AppearOnScrollContainer, AppearOnScrollTarget } from '@shared/AppearOnScroll/AppearOnScroll';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledCopyRights,
  StyledFooter,
  StyledFooterBackground,
  StyledFooterBottomDesktopImg,
  StyledFooterBottomMobileImg,
  StyledFooterContent,
  StyledFooterLink,
  StyledFooterLinksDesktop,
  StyledFooterLinksMobile,
  StyledFooterText,
  StyledFooterTextBold
} from './Footer.styles';

export const Footer = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <AppearOnScrollContainer desktopThreshold={0.1} mobileTreshold={0.1}>
      <StyledFooter>
        <StyledFooterContent>
          <StyledFooterBackground stroke="black" />
          <StyledFooterText>
            <AppearOnScrollTarget>
              {t('footer_text')}
              <br />
              <StyledFooterTextBold>
                {t('footer_text_bold')}
              </StyledFooterTextBold>
            </AppearOnScrollTarget>
          </StyledFooterText>
        </StyledFooterContent>
        <StyledFooterBottomMobileImg />
        <StyledFooterLinksMobile>
          <StyledCopyRights>{t('footer_copy_rights')}</StyledCopyRights>
          <StyledFooterLink
            to={RouteNames.privacyPolicy}
            onClick={() => window.scrollTo(0, 0)}
          >
            {t('footer_privacy_policy_link')}
          </StyledFooterLink>
          <StyledFooterLink
            to={RouteNames.termsAndConditions}
            onClick={() => window.scrollTo(0, 0)}
          >
            {t('footer_terms_conditions_link')}
          </StyledFooterLink>
        </StyledFooterLinksMobile>
        <StyledFooterLinksDesktop>
          <StyledFooterLink
            to={RouteNames.privacyPolicy}
          >
            {t('footer_privacy_policy_link')}
          </StyledFooterLink>
          <StyledCopyRights>{t('footer_copy_rights')}</StyledCopyRights>
          <StyledFooterLink
            to={RouteNames.termsAndConditions}
          >
            {t('footer_terms_conditions_link')}
          </StyledFooterLink>
        </StyledFooterLinksDesktop>
        <StyledFooterBottomDesktopImg />
      </StyledFooter>
    </AppearOnScrollContainer>
  );
};

export default memo(Footer);
