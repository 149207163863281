export const smartChainId = 56;
export const smartChainHexId = '0x38';

export const binanceNetworkData = {
  'name':'Binance Smart Chain Mainnet',
  'chain':'BSC',
  'rpc':[
    'https://bsc-dataseed1.binance.org',
    'https://bsc-dataseed2.binance.org',
    'https://bsc-dataseed3.binance.org',
    'https://bsc-dataseed4.binance.org',
    'https://bsc-dataseed1.defibit.io',
    'https://bsc-dataseed2.defibit.io',
    'https://bsc-dataseed3.defibit.io',
    'https://bsc-dataseed4.defibit.io',
    'https://bsc-dataseed1.ninicoin.io',
    'https://bsc-dataseed2.ninicoin.io',
    'https://bsc-dataseed3.ninicoin.io',
    'https://bsc-dataseed4.ninicoin.io',
    'wss://bsc-ws-node.nariox.org'
  ],
  'faucets':[
    'https://free-online-app.com/faucet-for-eth-evm-chains/'
  ],
  'nativeCurrency':{
    'name':'Binance Chain Native Token',
    'symbol':'BNB',
    'decimals':18
  },
  'infoURL':'https://www.binance.org',
  'shortName':'bnb',
  'chainId':56,
  'networkId':56,
  'slip44':714,
  'explorers':[
    {
      'name':'bscscan',
      'url':'https://bscscan.com',
      'standard':'EIP3091'
    }
  ]
};
