import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './index';

export type ReferralUserPayload = {
  address: string | null;
  email: string | null;
}

export type ReferralSlice = ReferralUserPayload & {
  referralHash: string | null;
  error: string | null;
}

const initialState: ReferralSlice = {
  address: null,
  email: null,
  referralHash: null,
  error: null
};

const reducers = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  createOrGetReferralHash: (_: ReferralSlice, action: PayloadAction<ReferralUserPayload>) => {},
  setReferralHash: (state: ReferralSlice, action: PayloadAction<{ referralHash: string }>) => {
    state.referralHash = action.payload.referralHash;
  },
  setError: (state: ReferralSlice, action: PayloadAction<{ message: string }>) => {
    state.error = action.payload.message;
  },
};

export const selectors = {
  referralHash: (state: RootState): string | null => state.referral.referralHash,
};

export const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers
});
type ReferralSliceActionsType = typeof referralSlice.actions;

export const actions = {
  ...referralSlice.actions
} as ReferralSliceActionsType;

export default referralSlice.reducer;
