import { ethers } from 'ethers';
import { _abi } from '@constants/abi/TokenABI';
import EtherProvider from '@services/web3Provider/etherProvider';

const TOKEN_ADDRESS = process.env.REACT_APP_ENVIRONMENT_MODE === 'production'
  ? process.env.REACT_APP_TOKEN_ADDRESS
  : process.env.REACT_APP_TEST_TOKEN_ADDRESS;

const TokenProvider = new ethers.Contract(
  TOKEN_ADDRESS as string,
  _abi,
  EtherProvider
);

export default TokenProvider;
