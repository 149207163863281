import { useTranslation } from 'react-i18next';
import { StyledTokenInfoDetails } from './TokenInfoDetails.styles';
import TokenProperty from './TokenProperty/TokenProperty';

const TokenInfoDetails = () => {
  const { t } = useTranslation();

  return (
    <StyledTokenInfoDetails>
      <TokenProperty
        property={t('token_info_property_name')}
        value={process.env.REACT_APP_TOKEN_INFO_NAME || ''}
      />
      <TokenProperty
        property={t('token_info_property_address')}
        value={process.env.REACT_APP_TOKEN_INFO_ADDRESS || ''}
      />
      <TokenProperty
        property={t('token_info_property_decimals')}
        value={process.env.REACT_APP_TOKEN_INFO_DECIMALS || ''}
      />
    </StyledTokenInfoDetails>
  );
};

export default TokenInfoDetails;
