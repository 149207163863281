import styled from 'styled-components';
import { MEDIA_QUERIES } from '@common/styles/media';

export const StyledNav = styled.div`
height: 100%;
display: flex;
justify-content: center;
align-items: center;

${MEDIA_QUERIES.TABLET} {
  display: none;
}
`;
