import { memo } from 'react';
import { StyledHamburger, StyledHamburgerLine } from './Hamburger.styles';

export interface HamburgerProps {
  onClick: () => void;
  isOpen: boolean;
}

const Hamburger = ({ isOpen, onClick }: HamburgerProps): JSX.Element => {
  return (
    <StyledHamburger onClick={onClick}>
      <StyledHamburgerLine isOpen={isOpen} delay={100} width={16} />
      <StyledHamburgerLine isOpen={isOpen} delay={200} width={28} />
      <StyledHamburgerLine isOpen={isOpen} delay={50} width={16} />
    </StyledHamburger>
  );
};

export default memo(Hamburger);
