
import { selectors } from '@/common/store/notificationSlice';
import { useSelector } from 'react-redux';
import Notification from './Notification/Notification';
import { StyledNotifications } from './Notifications.styles';

const Notifications = (): JSX.Element => {
  const notifications = useSelector(selectors.notifications);

  return (
    <StyledNotifications>
      {notifications.map((n) => <Notification notification={n} key={n.id} />)}
    </StyledNotifications>
  );
};

export default Notifications;
