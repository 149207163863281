import { memo } from 'react';
import { 
  StyledSocialMedia, 
  StyledSocialMediaItem, 
  StyledTwitterIcon, 
  StyledFacebookIcon, 
  StyledTelegramIcon, 
  StyledYoutubeIcon
} from './SocialMedia.styles';

const SocialMedia = () => {
  return (
    <StyledSocialMedia>
      <StyledSocialMediaItem href={process.env.REACT_APP_TWITTER} target={'_blank'}>
        <StyledTwitterIcon />
      </StyledSocialMediaItem>
      <StyledSocialMediaItem href={process.env.REACT_APP_FACEBOOK} target={'_blank'}>
        <StyledFacebookIcon />
      </StyledSocialMediaItem>
      <StyledSocialMediaItem href={process.env.REACT_APP_TELEGRAM} target={'_blank'}>
        <StyledTelegramIcon />
      </StyledSocialMediaItem>
      <StyledSocialMediaItem href={process.env.REACT_APP_YOUTUBE} target={'_blank'}>
        <StyledYoutubeIcon />
      </StyledSocialMediaItem>
    </StyledSocialMedia>
  );
};

export default memo(SocialMedia);
