import { FontNames } from '@common/styles/fontNames';
import { MEDIA_QUERIES } from '@common/styles/media';
import { ReactComponent as LogoSvg } from '@img/logo.svg';
import styled, { css } from 'styled-components';

export interface StyledNavItem {
  active?: boolean;
}

export const StyledCenteredAroundItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 45%;

  ${MEDIA_QUERIES.TABLET} {
    flex-direction: column;
    justify-content: flex-start;
    width: auto;
  }
`;

export const StyledNavItems = styled.div`
  max-width: 1150px;
  width: 100%;
  height: 100%;
  padding: 10px 150px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${MEDIA_QUERIES.TABLET} {
    flex-direction: column;
    justify-content: flex-start;
    padding: 50px;
    height: auto;
  }
`;

export const StyledNavItem = styled.a<StyledNavItem>`
  font-family: ${FontNames.graphikExtraLight};
  text-transform: uppercase;
  font-size: 0.8rem;
  transition: all 0.2s;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;

  ::before {
      content: '';
      display: block;
      height: 1px;
      background-color: black;
      position: absolute;
      width: 0;
      transition: width 0.2s;
      transition-timing-function: ease-out;
      bottom: -5px;
    }

  :hover {
    color: #fff;
  }

  ${({ active }) => active && css`
    font-family: ${FontNames.graphikRegular};

    ::before {
      width: 100%;
    }
    :hover {
      color: black;
    }
  `}

  ${MEDIA_QUERIES.TABLET} {
    font-size: 1.2rem;
    margin: 12px 0;
  }
`;

export const StyledNavLogo = styled.a`
  height: 100%;
  cursor: pointer;
`;

export const StyledLogo = styled(LogoSvg)`
  height: 100%;
`;
