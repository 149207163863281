import Languages from '../Languages/Languages';
import NavItems from '../NavItems/NavItems';
import SocialMedia from '../SocialMedia/SocialMedia';
import { 
  StyledNav
} from './DesktopNav.styles';

const DesktopNav = () => {
  return (
    <StyledNav>
      <Languages />
      <NavItems />
      <SocialMedia />
    </StyledNav>
  );
};

export default DesktopNav;
