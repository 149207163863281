import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HomeSections } from '../constants';
import { RootState } from './index';

export interface HomePageSliceInstance {
  section?: HomeSections,
  navigateToSection?: HomeSections,
  scrollY: number;
}

const initialState: HomePageSliceInstance = {
  scrollY: 0,
};

const reducers = {
  setSection: (state: HomePageSliceInstance, action: PayloadAction<HomeSections | undefined>) => {
    state.section = action.payload;
  },
  setNavigateToSection: (state: HomePageSliceInstance, action: PayloadAction<HomeSections | undefined>) => {
    state.navigateToSection = action.payload;
  },
  setScrollY: (state: HomePageSliceInstance, action: PayloadAction<number>) => {
    state.scrollY = action.payload;
  },
};

export const homePageSelectors = {
  section: (state: RootState): HomeSections | undefined => state.homePage.section,
  navigateToSection: (state: RootState): HomeSections | undefined => state.homePage.navigateToSection,
  scrollY: (state: RootState): number => state.homePage.scrollY,
};

export const homeSlice = createSlice({
  name: 'homePage',
  initialState,
  reducers
});

type HomePageSliceActionsType = typeof homeSlice.actions;

export const homePageActions = {
  ...homeSlice.actions
} as HomePageSliceActionsType;

export default homeSlice.reducer;
