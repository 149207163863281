import { FontNames } from '@/common/styles/fontNames';
import { MEDIA_QUERIES } from '@/common/styles/media';
import { ReactComponent as RotatedArrow } from '@img/rotated-arrow.svg';
import styled from 'styled-components';

export const StyledHelpUsGrow = styled.div`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 20px);
  margin: 0 auto;
  padding: 60px 0;

  ${MEDIA_QUERIES.MOBILE} {
    padding: 0 0 60px 0;
    box-sizing: border-box;
  }
`;

export const StyledHelpUsGrowArrow = styled(RotatedArrow)``;

export const StyledHelpUsGrowHeader = styled.h2`
  font-size: 4em;
  font-family: ${FontNames.sharpGroteskLight};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 45px 0;

  ${MEDIA_QUERIES.MOBILE} {
    font-size: max(11vw, 16px);
  }
`;

export const StyledHelpUsgrowHeaderBold = styled.span`
  font-family: ${FontNames.sharpGroteskMedium};
`;

export const StyledHelpUsGrowDesc = styled.p`
  max-width: 600px;
  text-align: center;
  font-family: ${FontNames.graphikLight};
  margin: 10px;
`;

export const StyledHelpUsGrowDescBold = styled.p`
  font-family: ${FontNames.graphikMedium};
  text-align: center;
`;
