import React from 'react';
import { Route, Routes as ReactRoutes, Navigate } from 'react-router-dom';
import { RouteNames } from '@common/constants';
import UseScrollToTopDuringNavigation from './UseScrollToTopDuringNavigation';
import HelpUsGrow from './HelpUsGrow/HelpUsGrow';

const Home = React.lazy(() => import('./Home/Home'));
const PrivatePresale = React.lazy(() => import('./PrivatePresale/PrivatePresale'));
const WhiteListedPresale = React.lazy(() => import('./WhiteListedPresale/WhiteListedPresale'));
const Vesting = React.lazy(() => import('./Vesting/Vesting'));
const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy/PrivacyPolicy'));
const TermsAndConditions = React.lazy(() => import('./TermsAndConditions/TermsAndConditions'));

const Routes = () => {
  UseScrollToTopDuringNavigation();

  return (
    <ReactRoutes>
      <Route
        path={RouteNames.home}
        element={
          <Home />
        }
      />
      <Route
        path={RouteNames.privacyPolicy}
        element={
          <PrivacyPolicy />
        }
      />
      <Route
        path={RouteNames.termsAndConditions}
        element={
          <TermsAndConditions />
        }
      />
      {/*<Route*/}
      {/*  path={RouteNames.privatePresale}*/}
      {/*  element={*/}
      {/*    <PrivatePresale />*/}
      {/*  }*/}
      {/*/>*/}
      {/*<Route*/}
      {/*  path={RouteNames.whiteListedPresale}*/}
      {/*  element={*/}
      {/*    <WhiteListedPresale />*/}
      {/*  }*/}
      {/*/>*/}
      <Route
        path={RouteNames.vesting}
        element={
          <Vesting />
        }
      />
      <Route
        path={RouteNames.helpUsGrow}
        element={
          <HelpUsGrow />
        }
      />
      <Route path="*" element={<Navigate to={RouteNames.home} replace />} />
    </ReactRoutes>
  );
};

export default Routes;
