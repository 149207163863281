import { useTranslation } from 'react-i18next';
import { 
  StyledHelpUsGrow, 
  StyledHelpUsGrowArrow, 
  StyledHelpUsGrowDesc, 
  StyledHelpUsGrowDescBold, 
  StyledHelpUsGrowHeader, 
  StyledHelpUsgrowHeaderBold 
} from './HelpUsGrowHeader.styles';

const HelpUsGrowHeader = (): JSX.Element => {
  const { t } = useTranslation();
  
  return (
    <StyledHelpUsGrow>
      <StyledHelpUsGrowArrow />
      <StyledHelpUsGrowHeader>
        <StyledHelpUsgrowHeaderBold>
          {t('help_us_grow_header_bold')}
        </StyledHelpUsgrowHeaderBold>
        {t('help_us_grow_header')}
      </StyledHelpUsGrowHeader>
      <StyledHelpUsGrowDesc>
        {t('help_us_grow_desc_1')}{' '}
        <StyledHelpUsGrowDescBold>
          {t('help_us_grow_desc_2_bold')}{' '}
        </StyledHelpUsGrowDescBold>
        {t('help_us_grow_desc_3')}{' '}
      </StyledHelpUsGrowDesc>
      <StyledHelpUsGrowDescBold>{t('help_us_grow_desc_bold_2')}</StyledHelpUsGrowDescBold>
    </StyledHelpUsGrow>
  );
};

export default HelpUsGrowHeader;
