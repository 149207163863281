import { ethers } from 'ethers';
import { _abi } from '@constants/abi/FactoryVestingControllerABI';
import EtherProvider from '@services/web3Provider/etherProvider';

const TOKEN_ADDRESS = process.env.REACT_APP_ENVIRONMENT_MODE === 'production'
  ? process.env.REACT_APP_VESTING_FACTORY_ADDRESS
  : process.env.REACT_APP_TEST_VESTING_FACTORY_ADDRESS;

const FactoryVestingControllerProvider = new ethers.Contract(
  TOKEN_ADDRESS as string,
  _abi,
  EtherProvider
);

export default FactoryVestingControllerProvider;
