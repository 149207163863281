// Must be sync with ./fonts.css
export enum FontNames {
  graphikExtraLight = 'graphik-extralight',
  graphikLight = 'graphik-light',
  graphikMedium = 'graphik-medium',
  graphikRegular = 'graphik-regular',
  graphikSemiBold = 'graphik-semibold',
  sharpGroteskBook = 'sharpgrotesk-book',
  sharpGroteskLight = 'sharpgrotesk-light',
  sharpGroteskMedium = 'sharpgrotesk-medium',
  sharpGroteskSemiBold = 'sharpgrotesk-semibold'
}
