import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers';

import reportWebVitals from './reportWebVitals';
import App from '@common/App/App';
import configureStoreLazy from '@/common/store/index';

const getLibrary = (provider: any) => {
  const library = new ethers.providers.Web3Provider(provider);
  // library.pollingInterval = 8000; // frequency provider is polling
  return library;
};

const root = document.getElementById('root') as HTMLElement;

const store = configureStoreLazy();

ReactDOM.render(
  <Provider store={store}>
    <Web3ReactProvider getLibrary={getLibrary}>
      <App />
    </Web3ReactProvider>
  </Provider>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
