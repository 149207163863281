import { StyledErrorMessage, StyledMessage } from './ErrorMessage.styles';

export interface ErrorMessageProps {
  message?: string;
}

const ErrorMessage = ({ message }: ErrorMessageProps): JSX.Element => {
  return (
    <StyledErrorMessage>
      <StyledMessage visible={!!message}>
        {message}
      </StyledMessage>
    </StyledErrorMessage>
  );
};

export default ErrorMessage;
