import styled, { keyframes } from 'styled-components';

export const StyledHamburger = styled.button`
  margin: auto auto;
  height: 22px;
  width: 22px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transform: rotate(45deg);
`;

export interface StyledHamburgerLineProps {
  isOpen: boolean;
  delay: number;
  width: number;
}

const hideLineAnimation = keyframes`
  100% {
    transform: translateX(-150px);
  }
`;

export const StyledHamburgerLine = styled.span<StyledHamburgerLineProps>`
  background-color: ${({ theme }) => theme.colors.black};
  width: ${({ width }) => width}px;
  height: 2px;

  animation: ${({ isOpen }) => isOpen && hideLineAnimation};
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-duration: 300ms;
  animation-delay: ${({ delay }) => delay}ms;
`;
