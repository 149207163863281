import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { domainSelectors } from '../../store/domainSlice';
import { MOBILE_NAV_SHOW_UP_TIME_MS } from '../Header/MobileNav/MobileNav.styles';

const UseBlockAppHeightDuringTheMobileNavOpen = () => {
  const isMobileNavOpen = useSelector(domainSelectors.isMobileNavOpen);
  const [scrollYBeforeNavOpen, setScrollYBeforeNavOpen] = useState<number | null>(null);

  useEffect(() => {
    if (isMobileNavOpen) {
      setTimeout(() => {
        setScrollYBeforeNavOpen(window.scrollY);
      }, MOBILE_NAV_SHOW_UP_TIME_MS * 2);
    } else if (scrollYBeforeNavOpen !== null) {
      setTimeout(() => {
        window.scrollTo(0, scrollYBeforeNavOpen);
      }, 1);
      setScrollYBeforeNavOpen(null);
    }
  }, [isMobileNavOpen]);

  return scrollYBeforeNavOpen !== null; 
};

export default UseBlockAppHeightDuringTheMobileNavOpen;
