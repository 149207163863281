export default {
  colors: {
    black: '#000000',
    white: '#ffffff',
    mineShaft: '#1F1F1F',
    heavyMetal: '#1D1D1B',
    silver: '#C9C9C9',
    mercury: '#E7E7E7',
    gallery: '#EDEDED',
    rioGrande: '#BEC802',
    wildSand: '#F5F5F5',
    codGray: '#0F0F0F',
    nobel: '#B6B6B6',
    error: '#ff0033'
  }
};
