import { domainActions } from '@/common/store/domainSlice';
import { MOBILE_MAX_WIDTH } from '@/common/styles/media';
import { throttle } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const UseCloseMobileNavOnDesktop = () => {
  const dispatch = useDispatch();
  
  const handleWindowResized = useCallback(() => {
    if (window.innerWidth > MOBILE_MAX_WIDTH) {
      dispatch(domainActions.setIsMobileNavOpen(false));
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', throttle(handleWindowResized, 1000));
    return () => {
      window.removeEventListener('resize', throttle(handleWindowResized, 1000));
    };
  }, [handleWindowResized]);
};

export default UseCloseMobileNavOnDesktop;
