import styled, { css } from 'styled-components';
import { ReactComponent as GreenLogoSvg } from '@img/green-logo.svg';
import { ReactComponent as ArrowRightSvg } from '@img/arrow2.svg';
import { FontNames } from '@/common/styles/fontNames';
import { MEDIA_QUERIES } from '@/common/styles/media';

export const StyledVisiblePart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;

  ${MEDIA_QUERIES.MOBILE} {
    width: 25px;
  }
`;

export const StyledGreenLogo = styled(GreenLogoSvg)`
  ${MEDIA_QUERIES.MOBILE} {
    width: 30px;
  }
`;

export const StyledArrow = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export interface ArrowProps {
  isVisible?: boolean;
}

export const StyledArrowLeft = styled(ArrowRightSvg)<ArrowProps>`
  position: absolute;
  transition: .5s all;

  ${(props) => props.isVisible && css`
    opacity: 0;
  `}

  ${MEDIA_QUERIES.MOBILE} {
    width: 22px;
  }
`;

export const StyledArrowRight = styled(StyledArrowLeft)`
  transform: rotate(180deg);
`;

export const StyledTokenInfoText = styled.div`
  writing-mode: vertical-rl;
  margin: 40px 0;
  margin-right: 2px;
  font-family: ${FontNames.sharpGroteskLight};

  ${MEDIA_QUERIES.MOBILE} {
    font-size: 0.9rem;
  }
`;

export const StyledTokenInfoTextBold = styled.span`
  font-family: ${FontNames.sharpGroteskMedium};
`;
