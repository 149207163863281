import { all, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';

import { actions, ReferralUserPayload } from '@/common/store/referralSlice';

function* getOrCreateReferralHash(action: PayloadAction<ReferralUserPayload>): Generator {
  const { email, address } = action.payload;
  const basePath = process.env.REACT_APP_API_BASE_PATH;

  try {
    const result = (yield axios.post(`${basePath}/reference/create`, {
      email,
      address
    })) as AxiosResponse<{ referralHash: string; }>;

    yield put(actions.setReferralHash({ referralHash: result.data.referralHash }));
  } catch (error) {
    yield put(actions.setError({ message: 'Something went wrong. Please contact our admin' }));
  }
}

export default function* ReferralSagas(): Generator {
  yield all([
    takeEvery([actions.createOrGetReferralHash], getOrCreateReferralHash)
  ]);
}
