import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { filter, uniqueId } from 'lodash';
import { RootState } from './index';

export interface NewNotification {
  message: string;
  header: string;
  duration?: number;
  id?: string;
  variant?: 'error' | 'success';
}

export type NotificationType = Required<NewNotification>;

export interface NotificationSliceInstance {
  notifications: NotificationType[];
}

const initialState: NotificationSliceInstance = {
  notifications: []
};

const reducers = {
  pushErrorNotification: (state: NotificationSliceInstance, action: PayloadAction<NewNotification>) => {
    state.notifications.push({
      id: uniqueId('error_notification_'),
      duration: 3500,
      variant: 'error',
      ...action.payload
    });
  },
  pushSuccessNotification: (state: NotificationSliceInstance, action: PayloadAction<NewNotification>) => {
    state.notifications.push({
      id: uniqueId('success_notification_'),
      duration: 3500,
      variant: 'success',
      ...action.payload
    });
  },
  removeNotification: (state: NotificationSliceInstance, action: PayloadAction<string>) => {
    state.notifications = filter(state.notifications, (n) => n.id !== action.payload);
  },
};

export const selectors = {
  notifications: (state: RootState): NotificationType[] => state.notification.notifications,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers
});

type NotificationSliceActionsType = typeof notificationSlice.actions;

export const actions = {
  ...notificationSlice.actions
} as NotificationSliceActionsType;

export default notificationSlice.reducer;
