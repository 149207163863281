import { useEffect } from 'react';
import i18next from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import translations from '@common/translations';
import { LanguagesEnum } from '@/common/constants';
import LanguagePersistentStorage from '@/common/LanguagePersistentStorage';

i18next.use(initReactI18next).init({
  resources: translations,
  lng: LanguagePersistentStorage.deserialize(),
  fallbackLng: LanguagesEnum.en,
});

const UseSetupLanguages = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    if (Object.values(LanguagesEnum).includes(language as LanguagesEnum)) {
      LanguagePersistentStorage.serialize(language as LanguagesEnum);
    }
  }, [language]);
};

export default UseSetupLanguages;
