import React from 'react';
import { Normalize } from 'styled-normalize';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import UseSetupLanguages from './hooks/UseSetupLanguages';
import UseBlockAppHeightDuringTheMobileNavOpen from './hooks/UseBlockAppHeightDuringTheMobileNavOpen';
import theme from '@common/styles/theme';
import GlobalStyles from '@common/styles/globalStyles';
import Routes from '@/routes';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { StyledApp } from './App.styles';
import '@styles/fonts.css';
import TokenInfo from './TokenInfo/TokenInfo';
import Fallback from './Fallback/Fallback';
import Notifications from './Notifications/Notifications';

const App = () => {
  const blockAppHeight = UseBlockAppHeightDuringTheMobileNavOpen();
  UseSetupLanguages();

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <React.Suspense fallback={<Fallback />}>
          <StyledApp blockHeight={blockAppHeight}>
            <Notifications />
            <Normalize />
            <GlobalStyles />
            <TokenInfo />
            <Header />
            <Routes />
            <Footer />
          </StyledApp>
        </React.Suspense>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
