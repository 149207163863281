import { ethers } from 'ethers';
import { _abi } from '@constants/abi/VestingContractABI';
import EtherProvider from '@services/web3Provider/etherProvider';

const VestingContractProvider = (address: string) => {
  return new ethers.Contract(
    address,
    _abi,
    EtherProvider
  );
};

export default VestingContractProvider;
