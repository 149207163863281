import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './index';

export type WalletConnection = {
  address: string | null;
  chainId: string | null;
  connected: boolean;
}

export interface WalletSliceInstance {
  address: string | null;
  chainId: string | null;
  connected: boolean;
  error?: unknown;
}

const initialState: WalletSliceInstance = {
  address: null,
  chainId: null,
  connected: false
};

const reducers = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  checkWalletConnect: () => {},
  setAddressData: (state: WalletSliceInstance, action: PayloadAction<WalletConnection>) => {
    state.address = action.payload.address;
    state.chainId = action.payload.chainId;
    state.connected = action.payload.connected;
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  metamaskConnect: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnectWallet: (state: WalletSliceInstance, action: PayloadAction<boolean>) => {
    state.connected = action.payload;
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  chainChange: (state: WalletSliceInstance, action: PayloadAction<{ customProvider: any }>) => {},
  setChain: (state: WalletSliceInstance, action: PayloadAction<string>) => {
    state.chainId = action.payload;
  },
  setError: (state: WalletSliceInstance, action: PayloadAction<unknown>) => {
    state.error = action.payload;
  },
};

export const selectors = {
  walletData: (state: RootState): WalletSliceInstance => state.wallet,
};

export const walletSlice = createSlice({
  name: 'application',
  initialState,
  reducers
});
type WalletSliceActionsType = typeof walletSlice.actions;

export const actions = {
  ...walletSlice.actions
} as WalletSliceActionsType;

export default walletSlice.reducer;
