import { createGlobalStyle } from 'styled-components';

const globalStyles = createGlobalStyle`
  html { 
    box-sizing: border-box;
    overflow-x: hidden;
  }

  *, *:before, *:after { 
    box-sizing: inherit; 
    touch-action: manipulation;

    &:not(input, textarea){
      /* user-select: none; */
      /* -webkit-user-select: none; */
    }
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    font-weight: normal;
    margin: 0;
    padding: 0; 
  }

  /* The fonts must be added here to be used in the modals as well */
  body {
    font-size: 16px;
    margin: 0;
  }

  ol, ul { list-style: none; }

  img {
    height: auto;
  }

  a {
    text-decoration: none;
    user-select: none;
    color: black;
    padding: 0 4px;
  }

  button {
    border: 0;
    outline: none;
  }

  input, textarea {
    &:focus {
      outline: none;
    }
  }
`;

export default globalStyles;
