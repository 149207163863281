import { useTranslation } from 'react-i18next';
import { 
  StyledVisiblePart,
  StyledGreenLogo,
  StyledTokenInfoText,
  StyledTokenInfoTextBold,
  StyledArrow,
  StyledArrowLeft,
  StyledArrowRight
} from './TokenInfoVisiblePart.styles';

export interface TokenInfoVisiblePartProps {
  expanded: boolean;
  hangeToggleExpand: () => void;
}

const TokenInfoVisiblePart = ({ expanded, hangeToggleExpand }: TokenInfoVisiblePartProps) => {
  const { t } = useTranslation();

  return (
    <StyledVisiblePart>
      <StyledGreenLogo />
      <StyledTokenInfoText>
        {t('token_info_text')}{' '}
        <StyledTokenInfoTextBold>
          {t('token_info_text_bold')}
        </StyledTokenInfoTextBold>
      </StyledTokenInfoText>
      <StyledArrow onClick={hangeToggleExpand}>
        <StyledArrowRight isVisible={!expanded} />
        <StyledArrowLeft isVisible={expanded}/>
      </StyledArrow>
    </StyledVisiblePart>
  );
};

export default TokenInfoVisiblePart;
