import { all, takeEvery, put } from 'redux-saga/effects';
import { providers } from 'ethers';
// import WalletConnectProvider from '@walletconnect/web3-provider/dist/umd/index.min.js';

import { actions } from '@/common/store/walletSlice';
import EtherProvider from '@services/web3Provider/etherProvider';
import { PersistentStorage } from '@utils/PersistentStorage';
import { smartChainHexId } from '@constants/chain';
import { PayloadAction } from '@reduxjs/toolkit';

function* checkWalletConnect(): Generator {
  try {
    const accounts = (yield EtherProvider.send('eth_accounts', []));
    const chainId = (yield EtherProvider.send('eth_chainId', [])) as string;

    const address = Array.isArray(accounts) && accounts[0] ? accounts[0] : null;

    const persistentStorage = new PersistentStorage('isConnected');
    const isConnected = persistentStorage.deserialize() as boolean | undefined;

    yield put(actions.setAddressData({
      address,
      chainId,
      connected: isConnected ?? false
    }));
  } catch (error) {
    yield put(actions.setError(error));
  }
}

function* metamaskConnect(): Generator {
  try {
    const accounts = (yield EtherProvider.send('eth_requestAccounts', [])) as string[];
    const chainId = (yield EtherProvider.send('eth_chainId', [])) as string;

    const address = Array.isArray(accounts) && accounts[0] ? accounts[0] : null;

    if (address) {
      const persistentStorage = new PersistentStorage('isConnected');
      persistentStorage.serialize(true);
    }

    yield put(actions.setAddressData({
      address,
      chainId,
      connected: true
    }));

  } catch (error) {
    yield put(actions.setError(error));
  }
}

function* chainChange(action: PayloadAction<{ customProvider: any }>): Generator {
  const { customProvider }: any = action.payload;
  const provider = customProvider ? customProvider : window.ethereum;

  if (!provider) {
    return;
  }

  try {
    yield provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: smartChainHexId }],
    });

    window.location.reload();
  } catch (error) {
    yield put(actions.setError(error));
  }
}

export default function* WalletSaga(): Generator {
  yield all([
    takeEvery([actions.checkWalletConnect], checkWalletConnect),
    takeEvery([actions.metamaskConnect], metamaskConnect),
    takeEvery([actions.chainChange], chainChange),
  ]);
}
