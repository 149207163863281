import { MEDIA_QUERIES } from '@/common/styles/media';
import styled, { css, keyframes } from 'styled-components';

export interface TokenInfoProps { 
  isVisible?: boolean;
}

const visiblePartWidth = 60;
export const visiblePartWidthMobile = 44;

const ShowUpTokenInfoAnimation = (width: number) => keyframes`
  0%, 50% {
    right: -${width}px;
  }

  100% {
    right: 0;
  }
`;

export const StyledTokenInfo = styled.div<TokenInfoProps>`
  background-color: ${({ theme }) => theme.colors.heavyMetal};
  box-shadow: 0 0 5px -4px ${({ theme }) => theme.colors.rioGrande};
  color: ${({ theme }) => theme.colors.rioGrande};
  border-radius: 15px 0 0 15px;
  position: fixed;
  top: calc(50% - 155px);
  transform: translateX(calc(100% - ${visiblePartWidth}px));
  transition: 0.4s all ease-out;
  right: 0;
  padding: 20px 10px;
  z-index: 1;
  display: grid;
  grid-template-columns: 50px auto;

  animation: ${ShowUpTokenInfoAnimation(visiblePartWidth)};
  animation-iteration-count: 1;
  animation-duration: 2s;

  ${(props) => props.isVisible && css`
    transform: translateX(0);
  `}

  ${MEDIA_QUERIES.MOBILE} {
    transform: translateX(calc(100% - ${visiblePartWidthMobile}px));
    animation: ${ShowUpTokenInfoAnimation(visiblePartWidth)};
    animation-iteration-count: 1;
    animation-duration: 2s;
    padding: 10px;
    max-width: 90vw;

    ${(props) => props.isVisible && css`
      transform: translateX(0);
    `}
  }
`;
