import { FontNames } from '@/common/styles/fontNames';
import styled from 'styled-components';

export interface NotificationProps {
  type: 'error' | 'success';
}

export const StyledNotification = styled.div`
  background-color: #1f1f1f;
  margin: 8px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
`;

export const StyledNotificationIconWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px;
`;

export const StyledNotificationContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledNotificationMessageHeaderWrapper = styled.div<NotificationProps>`
  font-family: ${FontNames.graphikMedium};
  height: 25px;
  color: ${props => props.type === 'success' ? '#bec802' : '#C0392B'};
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export const StyledNotificationMessageLine = styled.hr<NotificationProps>`
  width: 100%;
  margin: 0;
  border: 1px solid ${props => props.type === 'success' ? '#bec802' : '#C0392B'};
  border-left: 0px;
`;

export const StyledNotificationMessage = styled.h3`
  font-family: ${FontNames.graphikMedium};
  color: #e3dada;
  font-size: 15px;
  padding: 10px;
  text-align: center;
`;
