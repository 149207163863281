import { FontNames } from '@/common/styles/fontNames';
import styled, { css, keyframes } from 'styled-components';

export const StyledErrorMessage = styled.div`
  overflow: hidden;
  margin: 2px;
  min-height: 16px;
`;

const showUpAnimation = keyframes`
  0% { transform: translateY(-100%); }
  100% { transform: translateY(0); }
`;

export interface StyledMessageProps {
  visible?: boolean;
}

export const StyledMessage = styled.p<StyledMessageProps>`
  font-size: 0.85em;
  color: ${({ theme }) => theme.colors.error};
  font-family: ${FontNames.graphikLight};
  transform: translateY(-100%);
  
  ${(props) => props.visible && css`
    animation-name: ${showUpAnimation};
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    animation-duration: 0.1s;
    animation-delay: 0.1s;
  `}
`;
