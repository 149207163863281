import styled from 'styled-components';

export const StyledNotifications = styled.div`
  position: fixed;
  z-index: 1000;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  width: 90vw;
  max-width: 350px;
`;
