import { memo, useState } from 'react';
import TokenInfoDetails from './TokenInfoDetails/TokenInfoDetails';
import TokenInfoVisiblePart from './TokenInfoVisiblePart/TokenInfoVisiblePart';
import { 
  StyledTokenInfo,
} from './TokenInfo.styles';

const TokenInfo = () => {
  const [isTokenInfoVisible, setIsTokenInfoVisible] = useState(false);

  const handleToggleTokenInfoVisible = () => {
    setIsTokenInfoVisible((prev) => !prev);
  };

  return (
    <StyledTokenInfo isVisible={isTokenInfoVisible}>
      <TokenInfoVisiblePart expanded={isTokenInfoVisible} hangeToggleExpand={handleToggleTokenInfoVisible} />
      <TokenInfoDetails />
    </StyledTokenInfo>
  );
};

export default memo(TokenInfo);
