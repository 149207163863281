import { ethers, Signer } from 'ethers';
import { _abi } from '@constants/abi/VestingControllerABI';

const TOKEN_ADDRESS = process.env.REACT_APP_ENVIRONMENT_MODE === 'production'
  ? process.env.REACT_APP_PRIVATE_VESTING_ADDRESS
  : process.env.REACT_APP_TEST_PRIVATE_VESTING_ADDRESS;

const PrivateVestingSignerControllerProvider = (signer: Signer) => {
  return new ethers.Contract(
    TOKEN_ADDRESS as string,
    _abi,
    signer
  );
};

export default PrivateVestingSignerControllerProvider;
