import { FontNames } from '@/common/styles/fontNames';
import { MEDIA_QUERIES } from '@/common/styles/media';
import styled, { css } from 'styled-components';

export const StyledLanguages = styled.div`
  position: absolute;
  left: 15px;

  ${MEDIA_QUERIES.TABLET} {
    margin-top: 30px;
    position: relative;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
`;

export interface StyledLanguageProps {
  active?: boolean;
}

export const StyledLanguage = styled.button<StyledLanguageProps>`
  background-color: transparent;
  text-transform: uppercase;
  font-family: ${FontNames.graphikLight};
  cursor: pointer;

  ${({ active }) => active && css`
    font-family: ${FontNames.graphikMedium};
  `}

  transition: all 0.3s;
  color: ${({ theme }) => theme.colors.heavyMetal};

  ${({ active }) => !active && css`
    :hover {
      color: ${({ theme }) => theme.colors.white};
    }
  `}
`;
