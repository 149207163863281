import Tooltip from '@/shared/Tooltip/Tooltip';
import { useRef, useState } from 'react';
import { StyledTokenProperty, StyledTokenPropertyName, StyledTokenPropertyValue } from './TokenProperty.styles';

export interface TokenPropertyProps {
  property: string;
  value: string;
}

const TokenProperty = ({ value, property }: TokenPropertyProps) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const tooltipDisableRef = useRef<NodeJS.Timeout>();

  const copyValueToClickboard = () => {
    navigator.clipboard.writeText(value);
    setTooltipEnabled(true);
    clearTimeout(tooltipDisableRef.current);
    tooltipDisableRef.current = setTimeout(() => {
      setTooltipEnabled(false);
    }, 1500);
  };

  return (
    <StyledTokenProperty>
      <Tooltip text="Copied" disableHover disabled={!tooltipEnabled}>
        <StyledTokenPropertyValue onClick={copyValueToClickboard}>
          {value}
        </StyledTokenPropertyValue>
      </Tooltip>
      <StyledTokenPropertyName>
        {property}
      </StyledTokenPropertyName>
    </StyledTokenProperty>
  );
};

export default TokenProperty;
