import { ethers } from 'ethers';

// let provider: ethers.providers.Web3Provider | ethers.providers.JsonRpcProvider;

// if (typeof window !== 'undefined' && typeof window.ethereum !== 'undefined') {
//   provider = new ethers.providers.Web3Provider(window.ethereum);
// } else {
// }

export default new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org/');

// export default provider;
