import { MEDIA_QUERIES } from '@/common/styles/media';
import styled, { css } from 'styled-components';

export const StyledCard = styled.div`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.silver};
  background-color: ${({ theme }) => theme.colors.mercury};
  min-width: 500px;
  padding: 25px 20px;
  position: relative;

  ${MEDIA_QUERIES.MOBILE} {
    min-width: auto;
    width: 90vw;
  }
`;

export interface StyledCardContentProps {
  loading?: boolean;
}

export const StyledCardContent = styled.div<StyledCardContentProps>`
  ${({ loading }) => loading && css`
    filter: blur(1px);
  `}
`;

export const StyledCardLoading = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;
