import { all, takeEvery, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Signer } from 'ethers';

import { actions } from '@store/vestingSlice';
import { VestingService, VestingContracts } from '@services/vesting/VestingService';
import VestingContractSignerProvider from '@utils/contractProviders/vestingContract/VestingContractSignerProvider';

function* getVestingData(action: PayloadAction<{ address: string }>): Generator {
  const { address } = action.payload;
  const vestingService = new VestingService(address);

  try {
    const vestingData = (yield vestingService.getVestingData()) as VestingContracts[];
    yield put(actions.setVestingData(vestingData));
  } catch (error) {
    // TODO:: Error handling
  }
}

function* withdrawAvailable(action: PayloadAction<{ address: string, signer: Signer }>): Generator {
  const { address, signer } = action.payload;
  const vestingService = VestingContractSignerProvider(signer, address);

  try {
    const result = (yield vestingService.withdrawAvailable()) as any;
    const callback = yield result.wait();

    // TODO: Go to store, filter right vesting contract and set balance on 0 tokens
    window.location.reload();
  } catch (error) {
    // TODO:: Error handling
  }
}

export default function* VestingSaga(): Generator {
  yield all([
    takeEvery([actions.getVestingData], getVestingData),
    takeEvery([actions.withdrawAvailable], withdrawAvailable),
  ]);
}
