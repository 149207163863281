import { LanguagesEnum } from '@/common/constants';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledLanguages, StyledLanguage } from './Languages.styles';

const Languages = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  
  const handleLanguageChange = async (newLanguage: LanguagesEnum) => {
    await i18n.changeLanguage(newLanguage);
  };

  return (
    <StyledLanguages>
      <StyledLanguage 
        onClick={() => handleLanguageChange(LanguagesEnum.pl)} 
        active={language === LanguagesEnum.pl}
      >
        {LanguagesEnum.pl}
      </StyledLanguage>  
      <StyledLanguage 
        onClick={() => handleLanguageChange(LanguagesEnum.en)} 
        active={language === LanguagesEnum.en}
      >
        {LanguagesEnum.en}
      </StyledLanguage>
      <StyledLanguage 
        onClick={() => handleLanguageChange(LanguagesEnum.es)} 
        active={language === LanguagesEnum.es}
      >
        {LanguagesEnum.es}
      </StyledLanguage>
    </StyledLanguages>
  );
};

export default memo(Languages);
