import { FontNames } from '@/common/styles/fontNames';
import styled, { css } from 'styled-components';

export interface StyledTooltipProps {
  disabled?: boolean;
  disableHover?: boolean;
}

export const StyledTooltip = styled.div<StyledTooltipProps>`
  position: relative;
  display: inline-block;
  
  ${(props) => {
    if (props.disableHover) {
      return !props.disabled && css`
        span {
          visibility: visible;
        }
      `;
    }
    return !props.disabled && css`
      :hover span {
        visibility: visible;
      }
    `;
  }}
`;

export const StyledTooltipText = styled.span`
  visibility: hidden;
  max-width: 200px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 0.9rem;
  font-family: ${FontNames.graphikRegular};
 
  position: absolute;
  z-index: 1;

  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);

  ::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;
