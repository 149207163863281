import { ethers, Signer } from 'ethers';
import { _abi } from '@constants/abi/VestingContractABI';

const VestingContractSignerProvider = (signer: Signer, address: string) => {
  return new ethers.Contract(
    address,
    _abi,
    signer
  );
};

export default VestingContractSignerProvider;
