import { MEDIA_QUERIES } from '@/common/styles/media';
import styled from 'styled-components';
import { visiblePartWidthMobile } from '../TokenInfo.styles';

export const StyledTokenInfoDetails = styled.div`
  padding: 20px 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  ${MEDIA_QUERIES.MOBILE} {
    padding: 20px 20px 20px 0;
    max-width: calc(90vw - ${visiblePartWidthMobile}px);
  }
`; 
